<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!items">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>

        <b-card v-if="items">

            <div class="custom-search d-flex justify-content-between">
                <b-form-group>
                    <b-button variant="outline-primary" @click="toggleInsertForm">Ekle</b-button>
                </b-form-group>
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Arama</label>
                        <b-form-input
                            v-model="searchTerm"
                            placeholder="Arama"
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>

            <div v-if="showInsertForm" class="border-1 border-secondary p-2 mb-2 rounded-lg">
                <b-form @submit.prevent="insertNew">
                    <b-row>
                        <b-col sm="6" md="4" lg="4" xl="4" class="d-flex align-items-center">
                            <b-form-group class="w-100">
                                <b-form-select :options="typesComputed" v-model="insertData.petType"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="5" xl="6" class="d-flex align-items-center">
                            <b-form-group class="w-100">
                                <b-form-input v-model="insertData.name" placeholder="Aşı Adı" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="2" md="1" class="d-flex align-items-center">
                            <b-form-group>
                                <b-button variant="outline-primary px-1" type="submit">
                                    <feather-icon icon="SaveIcon" />
                                </b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="items"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'id', type: 'asc'}
                }">



                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <!-- Column: Name -->
                    <div v-if="props.column.field === 'name'">
                        <b-form-input class="tableInput" v-model="items[getItemIndex(props.row.id)].name" :readonly="$store.state.app.editableSlotId !== props.row.id" />
                    </div>

                    <div v-else-if="props.column.field === 'petType'">
                        <b-form-select class="tableInput" v-model="items[getItemIndex(props.row.id)].petType" :disabled="$store.state.app.editableSlotId !== props.row.id" :options="typesComputed" />
                    </div>

                    <!-- Column: Action -->
                    <div v-else-if="props.column.field === 'action'">
                        <b-button-group>
                            <b-button variant="outline-primary" @click="updateItem(props.row.id)" v-if="$store.state.app.editableSlotId === props.row.id">
                                <feather-icon icon="SaveIcon" />
                            </b-button>
                            <b-button variant="outline-primary" @click="setEditable(props.row.id)">
                                <feather-icon :icon="$store.state.app.editableSlotId !== props.row.id ? 'EditIcon':'RefreshCcwIcon'" />
                            </b-button>
                            <b-button variant="outline-primary" @click="deleteItem(props.row.id)">
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </b-button-group>
                    </div>

                    <!-- Column: Common -->
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>



                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                              Showing 1 to
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['5','10','20', '50']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>


        </b-card>
    </div>
</template>

<script>

import {
    BAlert,
    BSpinner,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BButtonGroup,
    BButton,
    BForm,
    BRow,
    BCol


} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import formData from 'form-data'


export default {
    name: "vacTypesList",
    data(){
        return {
            items: false,
            loading: true,
            showInsertForm: false,
            preChange : {},
            insertData: {
                name: '',
                petType: ''
            },
            types: [],



            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'id',
                    sortable: true,
                    type:'number',
                    width: '80px',
                    align: 'center',
                },
                {
                    label: 'Pet Türü',
                    field: 'petType'
                },
                {
                    label: 'Aşı Türü',
                    field: 'name'
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                    width: '160px',
                    align: 'center'
                },
            ],
            searchTerm: '',
        }
    },
    components: {
        BAlert,
        BSpinner,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BImg,
        BButtonGroup,
        BButton,
        BForm,
        BRow,
        BCol,


        VueGoodTable
    },
    created() {
        let t = this


        t.$http.get('/misc/pet/petTypes')
            .then(res => {
                if(res.data.status){
                    t.types = res.data.data
                }else if(res.data.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error=>{
            console.log(error)
        }).then(()=>{

            t.$http.get('/misc/pet/petVaccinationTypes?no-detail=1')
                .then(req => {
                    if(req.data.status){
                        t.items = req.data.data
                    }else if(req.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Hata`,
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                text: req.data.message,
                            },
                        })
                    }
                }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loading = false
            })

        })



        store.state.app.editableSlotId = 0
    },
    computed:{
        typesComputed(){
            let items = []
            for(let i = 0; i<this.types.length; i++){
                items.push({
                    value: this.types[i].id,
                    text: this.types[i].name
                })
            }
            return items
        }
    },
    methods: {
        setEditable(itemId){
            let fIndex = this.getItemIndex(itemId)
            let t = this
            if(store.state.app.editableSlotId === 0){
                this.preChange = structuredClone(this.items[fIndex])
                store.commit('app/SET_EDITABLE', itemId)
            }else if(store.state.app.editableSlotId === itemId){
                this.$swal({
                    title: 'Emin misiniz?',
                    text: "Yaptığınız değişiklikler kaybolacaktır.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if(result.isConfirmed){
                        this.resetItems(fIndex)
                        store.commit('app/SET_EDITABLE', 0)
                    }
                })
            }else{
                this.$swal({
                    title: 'Emin misiniz?',
                    text: "Mevcut düzenlemeyi tamamlamadan devam ederseniz, yaptığınız değişiklikler kaybolacaktır.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if(result.isConfirmed){
                        this.resetItems(t.getItemIndex(store.state.app.editableSlotId))
                        t.preChange = structuredClone(t.items[fIndex])
                        store.commit('app/SET_EDITABLE', itemId)
                    }
                })
            }
        },
        toggleInsertForm(){
            this.showInsertForm = !this.showInsertForm
        },
        getItemIndex(id){
            return this.items.findIndex(e=>{return e.id===id})
        },
        updateItem(id){

            let t = this
            let data = new formData()
            let fIndex = this.getItemIndex(id)
            let item = this.items[fIndex]

            data.append('name', item.name)
            data.append('petType', item.petType)

            store.commit('app/SET_LOADING', true)
            t.$http.post(
                'admin/pet/vaccination-type/edit/'+id,
                data
            ).then((result)=>{
                if(result.data.status){
                    if(result.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }
                }else{
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message?result.data.message:'İçerik Güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                    t.resetItems(fIndex)
                }
            }).catch((error)=>{
                console.log(error)

                t.$swal({
                    title: 'Hata!',
                    text: 'İçerik Güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
                t.resetItems(fIndex)
            }).then((e)=>{
                store.commit('app/SET_EDITABLE', 0)
                store.commit('app/SET_LOADING', false)
            })

        },
        deleteItem(itemId){
            let t = this

            t.$swal({
                title: 'Emin misiniz?',
                text: 'İçeriği sildiğinizde bağlantılı tüm verilerle beraber tamamen silinecektir. Bu içeriği silmek istediğinize emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-outline-info ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.commit('app/SET_LOADING', true)
                    t.$http.delete('admin/pet/vaccination-type/delete/'+itemId).then(function (e){
                        if(e.data.status){
                            if(e.data.message){
                                t.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Başarılı!`,
                                        icon: 'CheckIcon',
                                        variant: 'success',
                                        text: e.data.message,
                                    },
                                })
                            }
                            let itemIndex = t.items.findIndex(f=>{return f.id === itemId})
                            t.items.splice(itemIndex, 1)
                        }
                    }).catch(e=>{
                        console.log(e)
                    }).then(f=>{
                        store.commit('app/SET_LOADING', false)
                    });

                } else if (result.dismiss === 'cancel') {
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `İptal Edildi!`,
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: 'İçerik Silme İşlemi İptal Edildi.',
                        },
                    })
                }
            })
        },
        resetItems(curr){
            let t = this;
            let keys = Object.keys(t.preChange)
            for (let i = 0; i<keys.length; i++){
                t.items[curr][keys[i]] = t.preChange[keys[i]]
            }
        },
        insertNew(){
            let t = this
            let data = new formData()

            data.append('name', t.insertData.name)
            data.append('petType', t.insertData.petType)

            store.commit('app/SET_LOADING', true)
            t.$http.post(
                'admin/pet/vaccination-type/add',
                data
            ).then((result)=>{
                if(result.data.status){

                    t.insertData.name=''
                    t.insertData.petType=''

                    t.items.push(result.data.data)

                    if(result.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }
                }else{
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message?result.data.message:'İçerik Eklenemedi. Lütfen daha sonra tekrar deneyin.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                }
            }).catch((error)=>{
                console.log(error)
                t.$swal({
                    title: 'Hata!',
                    text: 'İçerik Eklenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
            }).then((e)=>{
                store.commit('app/SET_LOADING', false)
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.tableInput[readonly]{
    border: none !important;
}
</style>
